import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { Spinner, Button } from 'src/components';
import { Step } from './components';

const Multistep = ({ children, initialValues, handleSubmit, requestClose, fixed, labels }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (setTouched) => {
    if (typeof document !== 'undefined') document.getElementById('modal-body').scrollTop = 0;
    const nextStep = Math.min(stepNumber + 1, totalSteps - 1);

    setTouched({});
    setStepNumber(nextStep);
  };

  const previous = (setErros) => {
    if (typeof document !== 'undefined') document.getElementById('modal-body').scrollTop = 0;
    const previousStep = Math.max(stepNumber - 1, 0);

    setErros({});
    setStepNumber(previousStep);
  };

  const handleLocalSubmit = (values, actions) => {
    if (isLastStep) {
      actions.setSubmitting(true);
      handleSubmit(values, actions);
    } else {
      next(actions.setTouched);
      actions.validateForm();
    }
  };

  return (
    <div id="modal-body" className="w-full overflow-y-auto">
      <div className="sticky top-0 w-full bg-secondary-grey-light lg:hidden">
        <div className="flex items-center justify-between px-6 h-14">
          <ol className="flex items-center space-x-4">
            {[...Array(totalSteps)].map((_, i) => (
              <li
                key={i.toString()}
                className={`flex items-center justify-center w-8 h-8  rounded-full
                ${
                  stepNumber === i
                    ? 'border-2 border-primary-light text-primary-light bg-white'
                    : ''
                }
                ${stepNumber > i ? 'bg-primary-light text-white' : ''}
                ${stepNumber < i ? 'border-2 border-gray-300 text-secondary bg-white' : ''}`}
                //   className={`flex items-center justify-center w-8 h-8 text-white rounded-full
                // ${stepNumber === i ? 'bg-secondary' : ''}
                // ${stepNumber > i ? 'bg-primary-light' : ''}
                // ${stepNumber < i ? 'bg-gray-300' : ''}`}
              >
                {i === totalSteps - 1 ? (
                  <svg viewBox="0 0 18 18" className="w-4 h-4 fill-current">
                    <path d="M16.0166 2.69922L15.0249 3.69971C12.2912 6.43934 9.37448 9.54899 6.64359 12.3267L2.86982 9.21996L1.78157 8.32477L0 10.4925L1.07947 11.3877L5.85373 15.3194L6.84544 16.1356L7.74061 15.2316C10.765 12.2008 14.0414 8.65642 17.0083 5.68313L18 4.68264L16.0166 2.69922Z" />
                  </svg>
                ) : (
                  <span className="font-bold">{`${i + 1}`}</span>
                )}
              </li>
            ))}
          </ol>
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </div>

      <div className="flex pb-4 lg:flex lg:pb-0">
        <div className="relative hidden lg:block">
          <div className="absolute inset-y-0 z-0 w-16 bg-secondary-grey-light" />
          <div className="sticky top-0 flex flex-col items-center flex-shrink-0 w-16">
            <ol className="py-4 space-y-4">
              {[...Array(totalSteps)].map((_, i) => (
                <li
                  key={i.toString()}
                  className={`flex items-center justify-center w-8 h-8  rounded-full
                ${
                  stepNumber === i
                    ? 'border-2 border-primary-light text-primary-light bg-white'
                    : ''
                }
                ${stepNumber > i ? 'bg-primary-light text-white' : ''}
                ${stepNumber < i ? 'border-2 border-gray-300 text-secondary bg-white' : ''}`}
                  //   className={`flex items-center justify-center w-8 h-8 text-white rounded-full
                  // ${stepNumber === i ? 'bg-secondary' : ''}
                  // ${stepNumber > i ? 'bg-primary-light' : ''}
                  // ${stepNumber < i ? 'bg-gray-300' : ''}`}
                >
                  {i === totalSteps - 1 ? (
                    <svg viewBox="0 0 18 18" className="w-4 h-4 fill-current">
                      <path d="M16.0166 2.69922L15.0249 3.69971C12.2912 6.43934 9.37448 9.54899 6.64359 12.3267L2.86982 9.21996L1.78157 8.32477L0 10.4925L1.07947 11.3877L5.85373 15.3194L6.84544 16.1356L7.74061 15.2316C10.765 12.2008 14.0414 8.65642 17.0083 5.68313L18 4.68264L16.0166 2.69922Z" />
                    </svg>
                  ) : (
                    <span className="font-bold">{`${i + 1}`}</span>
                  )}
                </li>
              ))}
            </ol>
          </div>
        </div>

        <div className="w-full lg:-pl-16">
          <div className="sticky top-0 right-0 items-center justify-end hidden pt-6 pr-6 lg:flex">
            <button type="button" onClick={requestClose}>
              <svg viewBox="0 0 20 20" className="w-5 h-5">
                <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
              </svg>
            </button>
          </div>
          <Formik
            initialValues={snapshot}
            validationSchema={step.props.schema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              handleLocalSubmit(values, actions);
            }}
          >
            {({ isValid, isSubmitting, dirty, setErrors }) => (
              <Form className="px-6 pt-6 lg:pt-0 lg:px-20 lg:pb-4">
                {fixed && fixed}
                {!isSubmitting ? (
                  <>
                    {step}
                    <div className="flex items-center justify-between pt-12 space-x-2">
                      {stepNumber > 0 ? (
                        <button
                          type="button"
                          onClick={() => previous(setErrors)}
                          disabled={isSubmitting}
                          className="flex items-center justify-center w-12 h-12 text-gray-400 transition border-2 border-gray-400 rounded-full hover:text-secondary focus:text-secondary hover:border-secondary focus:border-secondary focus:outline-none"
                        >
                          <svg viewBox="0 0 24 24" className="w-6 h-6 stroke-current">
                            <path
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 19l-7-7 7-7"
                            />
                          </svg>
                        </button>
                      ) : (
                        <div className="w-16" />
                      )}
                      <div className="w-60">
                        <Button
                          type="submit"
                          size="md"
                          disabled={!(isValid && dirty) || isSubmitting}
                        >
                          {isLastStep ? labels.last : labels.next}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center pb-12 text-primary-light">
                    <div className="h-12">
                      <Spinner />
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

Multistep.Step = Step;

Multistep.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  requestClose: PropTypes.func.isRequired,
  fixed: PropTypes.node,
  labels: PropTypes.shape({
    next: PropTypes.string.isRequired,
    last: PropTypes.string.isRequired,
  }),
};

Multistep.defaultProps = {
  fixed: null,
  labels: {
    next: 'Suivant',
    last: 'Confirmer',
  },
};

export default Multistep;
