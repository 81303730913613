import { DateTime } from 'luxon';

const timestampToDateFormat = (ts, lg) => {
  try {
    if (lg.startsWith('fr')) return DateTime.fromMillis(ts).toFormat("dd'/'MM'/'yyyy");
    return DateTime.fromMillis(ts).toFormat("MM'/'dd'/'yyyy");
  } catch (err) {
    return '';
  }
};

export default timestampToDateFormat;
