import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Button from './butttons';
import InputField from './inputs';

const searchSchema = Yup.object().shape({
  search: Yup.string().max(250, 'forms.too_long'),
});

const Searcher = ({ search, setSearch, placeholder, withSubmit }) => (
  <Formik
    initialValues={{ search }}
    validationSchema={searchSchema}
    onSubmit={(values) => setSearch(values.search)}
  >
    <Form className="w-full">
      {!withSubmit ? (
        <InputField
          type="search"
          name="search"
          placeholder={placeholder}
          translate={false}
          setSearch={setSearch}
        />
      ) : (
        <div className="flex items-center space-x-4">
          <InputField
            type="search"
            name="search"
            placeholder={placeholder}
            translate={false}
            setSearch={setSearch}
            style={{ height: '44px' }}
          />
          <div className="w-2/5">
            <Button type="submit">Search</Button>
          </div>
        </div>
      )}
    </Form>
  </Formik>
);

Searcher.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  withSubmit: PropTypes.bool,
};

Searcher.defaultProps = {
  placeholder: 'Search...',
  withSubmit: false,
};

export default Searcher;
