import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import debounce from 'lodash.debounce';

import { httpClient } from 'src/helpers';
import { updateEndorser } from 'src/store';
import { Modal, Button, InputField, Snackbar } from 'src/components';

const { GATSBY_VERTICAL } = process.env;

const ModalEndorser = ({ open, requestClose }) => {
  const dispatch = useDispatch();
  const { clientId, endorser } = useSelector((state) => state.user);

  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const [newUser] = useState(
    typeof window !== 'undefined' && localStorage.getItem('newUser') === 'true'
  );
  const [editing, setEditing] = useState(newUser);
  const [search, setSearch] = useState('');
  const [request, setRequest] = useState({ attaching: false, loading: false, status: null });

  const validate = async ({ search: str }) => {
    setSearch(str);
    if (str === '' || str.length < 4) {
      setRequest({ ...request, loading: false, status: null });
      return;
    }

    setRequest({ ...request, loading: true });

    try {
      const { data } = await httpClient.get(`/endorsers/${str}/exists`);
      setRequest({ ...request, loading: false, status: data.exists ? 200 : 404 });
    } catch (err) {
      setRequest({ ...request, loading: false, status: 404 });
    }
  };

  const onSubmit = async ({ search: str }) => {
    setSnackbarState({ type: 'LOADING' });
    setRequest({ ...request, loading: false, attaching: true });

    try {
      const { data } = await httpClient.put(`/clients/${clientId}/endorser`, { name: str });

      if (data && data.attached) {
        setSnackbarState({ type: 'SUCCESS' });
        dispatch(updateEndorser(search.toLowerCase()));

        setTimeout(() => {
          requestClose();
        }, 1000);
      } else throw Error('Update endorser failed.');
    } catch (err) {
      setSnackbarState({ type: 'ERROR' });
      setRequest({ ...request, loading: false, status: null });
    }
  };

  return (
    <Modal
      open={open}
      requestClose={requestClose}
      closeOnEsc={!newUser && !request.attaching && !request.loading}
      closeOnOverlay={!newUser && !request.attaching && !request.loading}
    >
      <Modal.Body className="relative space-y-6">
        {!newUser && (
          <div className="absolute inset-x-0 top-0 flex items-center justify-between px-6 pt-6">
            {editing && !request.attaching ? (
              <button type="button" onClick={() => setEditing(false)}>
                <svg className="w-6 h-6" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={4}
                    d="M15 19l-7-7 7-7"
                    stroke="currentColor"
                    fill="none"
                  />
                </svg>
              </button>
            ) : (
              <div />
            )}
            {!request.attaching && (
              <button type="button" onClick={requestClose}>
                <svg viewBox="0 0 20 20" className="w-5 h-5">
                  <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
                </svg>
              </button>
            )}
          </div>
        )}

        <div className="w-full max-w-xl px-6 pb-12 mx-auto space-y-6">
          <Snackbar
            type={snackbarState.type}
            message={snackbarState.msg}
            requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
          />

          <div className="flex flex-col pt-6 pb-8">
            {GATSBY_VERTICAL === 'GREENSHIELD' ? (
              <>
                <h5 className="font-bold text-center">Do you have a referral code?</h5>
                <p className="pt-2 text-sm text-center text-secondary">
                  Tell us if you have received a referral code for the Sharecare Health Security
                  Pass program?
                </p>
              </>
            ) : (
              <>
                <h5 className="font-bold text-center">Who endorsed you?</h5>
                <p className="pt-2 text-sm text-center text-secondary">
                  Tell us which organization endorsed you.
                </p>
              </>
            )}
            {newUser && (
              <p className="pt-2 text-sm text-center text-secondary">
                {GATSBY_VERTICAL === 'GREENSHIELD'
                  ? 'If none, please click on “I don’t have a referral code” '
                  : 'If none, please select the ‘I have no endorser’ button below.'}
              </p>
            )}
          </div>

          {editing ? (
            <div>
              <div className="flex items-center justify-between space-x-4">
                <Formik
                  initialValues={{ search }}
                  validateOnBlur={false}
                  validate={debounce(validate, 500)}
                  onSubmit={onSubmit}
                >
                  <Form className="w-full">
                    <div className="max-w-xs mx-auto">
                      <InputField
                        type="search"
                        name="search"
                        placeholder={
                          GATSBY_VERTICAL === 'GREENSHIELD'
                            ? 'Add referral organization'
                            : 'Add endorser'
                        }
                        translate={false}
                        setSearch={setSearch}
                        disabled={request.attaching}
                      />

                      {request.status && !request.loading && (
                        <div className="mt-1 ml-1">
                          {request.status === 200 ? (
                            <div className="flex items-center space-x-2 text-primary-light">
                              <svg viewBox="0 0 8 8" className="w-3 h-3 fill-current">
                                <path d="M8 4C8 6.20915 6.20915 8 4 8C1.79086 8 0 6.20915 0 4C0 1.79086 1.79086 0 4 0C6.20915 0 8 1.79086 8 4ZM3.53732 6.11797L6.50506 3.15023C6.60584 3.04945 6.60584 2.88605 6.50506 2.78527L6.14011 2.42032C6.03934 2.31953 5.87594 2.31953 5.77515 2.42032L3.35484 4.84061L2.22485 3.71063C2.12408 3.60985 1.96068 3.60985 1.85989 3.71063L1.49494 4.07558C1.39416 4.17635 1.39416 4.33976 1.49494 4.44053L3.17235 6.11795C3.27315 6.21874 3.43653 6.21874 3.53732 6.11797Z" />
                              </svg>
                              <span>
                                {GATSBY_VERTICAL === 'GREENSHIELD'
                                  ? 'Referral found'
                                  : 'Endorser found'}
                              </span>
                            </div>
                          ) : (
                            <div className="flex items-center space-x-2 text-danger">
                              <svg viewBox="0 0 8 8" className="w-3 h-3 fill-current">
                                <path d="M6.67806 0.205128C6.98196 -0.0683761 7.46819 -0.0683761 7.77208 0.205128C8.07597 0.509022 8.07597 0.995252 7.77208 1.29915L5.09782 4.0038L7.77208 6.67806C8.07597 6.98196 8.07597 7.46819 7.77208 7.77208C7.46819 8.07597 6.98196 8.07597 6.67806 7.77208L4.0038 5.09782L1.29915 7.77208C0.995252 8.07597 0.509022 8.07597 0.205128 7.77208C-0.0683761 7.46819 -0.0683761 6.98196 0.205128 6.67806L2.90978 4.0038L0.205128 1.29915C-0.0683761 0.995252 -0.0683761 0.509022 0.205128 0.205128C0.509022 -0.0683761 0.995252 -0.0683761 1.29915 0.205128L4.0038 2.90978L6.67806 0.205128Z" />
                              </svg>
                              <span>
                                {GATSBY_VERTICAL === 'GREENSHIELD'
                                  ? 'Referral not found'
                                  : 'Endorser not found'}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="pt-12">
                      <div className="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                        {newUser && (
                          <div className="w-3/5">
                            <Button
                              onClick={requestClose}
                              disabled={request.loading || request.attaching}
                              size="md"
                              variant="secondary"
                            >
                              {GATSBY_VERTICAL === 'GREENSHIELD'
                                ? 'I don’t have a referral code'
                                : 'I have no endorser'}
                            </Button>
                          </div>
                        )}
                        <div className="w-2/5">
                          <Button
                            type="submit"
                            loading={request.loading || request.attaching}
                            disabled={
                              request.loading || request.status !== 200 || request.attaching
                            }
                            size="md"
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-between px-6 py-4 border rounded-md">
                <div className="flex items-center space-x-2">
                  <span>{GATSBY_VERTICAL === 'GREENSHIELD' ? 'Referral:' : 'Endorser:'}</span>
                  {endorser === 'default' ? (
                    <span className="text-sm">None</span>
                  ) : (
                    <span className="px-2 py-1 text-sm capitalize bg-gray-200 rounded-md">
                      {endorser}
                    </span>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => setEditing(true)}
                  className="hidden text-blue-500 hover:underline focus:underline hover:text-blue-600 focus:text-blue-600 focus:outline-none sm:block"
                >
                  Change
                </button>
              </div>
              <button
                type="button"
                onClick={() => setEditing(true)}
                className="w-full text-center text-blue-500 hover:underline focus:underline hover:text-blue-600 focus:text-blue-600 focus:outline-none sm:hidden"
              >
                Change
              </button>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

ModalEndorser.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
};

export default ModalEndorser;
