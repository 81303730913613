import { configureStore, createAsyncThunk } from '@reduxjs/toolkit';
import { cache } from 'swr';

import { httpClient } from 'src/helpers';

import userReducer, {
  setAuthenticating,
  connect,
  update,
  updateToken,
  updateEndorser,
} from './user';
import modalReducer, { openQuote, closeQuote } from './modal';
import modalFacilityAddReducer, { openFacilityAdd, closeFacilityAdd } from './modalFacilityAdd';

export const signOut = createAsyncThunk('signout', async () => {
  await httpClient.post('/signout');
  localStorage.removeItem('auth');
  localStorage.setItem('signout', 'true');
  cache.clear();
});

export default configureStore({
  reducer: {
    user: userReducer,
    modal: modalReducer,
    modalFacilityAdd: modalFacilityAddReducer,
  },
  extraReducers: {
    [signOut.fulfilled]: () => {},
  },
});

export { setAuthenticating, connect, update, updateToken, updateEndorser };
export { openQuote, closeQuote };
export { openFacilityAdd, closeFacilityAdd };
