import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const classFromType = (t) => {
  switch (t) {
    case 'SUCCESS':
      return 'h-auto opacity-100 bg-opacity-20 text-primary-light bg-primary-light hide';
    case 'ERROR':
      return 'h-auto opacity-100 bg-opacity-20 text-red-500 bg-red-500';
    case 'LOADING':
      return 'h-auto opacity-100 bg-opacity-20 text-primary-light bg-primary-light';
    case 'HIDDEN':
      return 'h-0 opacity-0 bg-transparent';
    default:
      return 'h-0 opacity-0 bg-transparent';
  }
};

const Snackbar = ({ type, message, requestClose }) => {
  const { t } = useTranslation('common');

  return (
    <div className={`w-full transition-all rounded-lg flex items-center ${classFromType(type)}`}>
      <div className="flex items-center justify-between w-full px-6 py-2">
        {type === 'LOADING' && (
          <div className="flex items-center flex-grow">
            <svg viewBox="0 0 128 128" className="w-7 h-7">
              <g>
                <path
                  d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
                  fill="currentColor"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 64 64"
                  to="360 64 64"
                  dur="1800ms"
                  repeatCount="indefinite"
                />
              </g>
            </svg>
            <span className="pl-4">{message || 'Loading...'}</span>
          </div>
        )}
        {type === 'ERROR' && (
          <div className="flex items-center flex-grow">
            <svg viewBox="0 0 24 24" fill="none" className="stroke-current w-7 h-7">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="pl-4">
              {message || (!navigator.onLine ? t('snackbar.offline') : t('snackbar.server'))}
            </span>
          </div>
        )}
        {type === 'SUCCESS' && (
          <div className="flex items-center flex-grow">
            <svg viewBox="0 0 24 24" fill="none" className="stroke-current w-7 h-7">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span className="pl-4">{message || t('snackbar.success')}</span>
          </div>
        )}
        {type === 'ERROR' && (
          <div className="flex py-1">
            <button
              type="button"
              onClick={requestClose}
              className="p-1 transition rounded-full hover:bg-white hover:bg-opacity-20 focus:bg-white focus:bg-opacity-20 focus:outline-none"
            >
              <svg viewBox="0 0 24 24" fill="none" className="w-4 h-4 stroke-current">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

Snackbar.propTypes = {
  type: PropTypes.oneOf(['LOADING', 'ERROR', 'SUCCESS', 'HIDDEN']),
  message: PropTypes.string,
  requestClose: PropTypes.func.isRequired,
};

Snackbar.defaultProps = {
  type: 'HIDDEN',
  message: '',
};

export default Snackbar;
