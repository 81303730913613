import React from 'react';
import PropTypes from 'prop-types';

import URLS from 'src/constants/urls.json';
import VulcainLogo from 'src/assets/images/vulcain-logo-chatbot.svg';
import { Navigation, Footer } from './components';

const Layout = ({ children }) => {
  const closeChatbot = () => {
    document.getElementById('vulcain-chatbot').classList.remove('open');
  };

  return (
    <>
      <Navigation />
      {children}
      <Footer />
      <div id="vulcain-chatbot">
        <div className="flex justify-between" style={{ padding: '5px' }}>
          <img src={VulcainLogo} alt="Vulcain" style={{ maxHeight: '26px' }} />
          <button type="button" onClick={() => closeChatbot()} className="pr-2">
            <svg
              viewBox="0 0 24 24"
              className="w-6 h-6 text-gray-500 stroke-current focus:outline-none"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
                fill="none"
              />
            </svg>
          </button>
        </div>
        <iframe
          title="chatbot"
          className="flex-grow"
          src={`${
            URLS.VULCAIN[process.env.GATSBY_DEPLOYMENT]
          }/HealthSecurityCertification/HealthSecurityVerification.html`}
          scrolling="yes"
        />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
