import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const UserSettingsDropdown = ({ options }) => {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleMouseClick = (e) => {
    if (!dropdownRef.current || dropdownRef.current.contains(e.target)) return;
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => document.removeEventListener('mousedown', handleMouseClick);
  }, [open]);

  return (
    <div ref={dropdownRef} className="relative">
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="flex items-center justify-center p-3 rounded-full hover:bg-secondary-grey-light focus:bg-secondary-grey-light focus:outline-none"
      >
        <svg viewBox="0 0 16 16" className="w-5 h-5 fill-current">
          <path d="M9.5 13C9.5 13.3978 9.34196 13.7794 9.06066 14.0607C8.77936 14.342 8.39782 14.5 8 14.5C7.60218 14.5 7.22064 14.342 6.93934 14.0607C6.65804 13.7794 6.5 13.3978 6.5 13C6.5 12.6022 6.65804 12.2206 6.93934 11.9393C7.22064 11.658 7.60218 11.5 8 11.5C8.39782 11.5 8.77936 11.658 9.06066 11.9393C9.34196 12.2206 9.5 12.6022 9.5 13ZM9.5 8C9.5 8.39782 9.34196 8.77936 9.06066 9.06066C8.77936 9.34196 8.39782 9.5 8 9.5C7.60218 9.5 7.22064 9.34196 6.93934 9.06066C6.65804 8.77936 6.5 8.39782 6.5 8C6.5 7.60218 6.65804 7.22064 6.93934 6.93934C7.22064 6.65804 7.60218 6.5 8 6.5C8.39782 6.5 8.77936 6.65804 9.06066 6.93934C9.34196 7.22064 9.5 7.60218 9.5 8ZM9.5 3C9.5 3.39782 9.34196 3.77936 9.06066 4.06066C8.77936 4.34196 8.39782 4.5 8 4.5C7.60218 4.5 7.22064 4.34196 6.93934 4.06066C6.65804 3.77936 6.5 3.39782 6.5 3C6.5 2.60218 6.65804 2.22064 6.93934 1.93934C7.22064 1.65804 7.60218 1.5 8 1.5C8.39782 1.5 8.77936 1.65804 9.06066 1.93934C9.34196 2.22064 9.5 2.60218 9.5 3Z" />
        </svg>
      </button>
      {open && (
        <div
          className="absolute z-30 bg-white border right-10 top-5"
          style={{ boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}
        >
          <ul className="flex flex-col items-center justify-center py-2">
            {options
              .filter((el) => !el.danger && !el.hide)
              .map((el) => (
                <li key={el.label} className="w-full">
                  <button
                    type="button"
                    disabled={!el.active}
                    onClick={() => {
                      el.event();
                      setOpen(false);
                    }}
                    className="w-full px-6 py-2 text-left whitespace-nowrap hover:bg-secondary-gray-light-hover focus:bg-secondary-gray-light-hover focus:outline-none disabled:bg-transparent"
                  >
                    {el.label}
                  </button>
                </li>
              ))}
            {options
              .filter((el) => el.danger && !el.hide)
              .map((el) => (
                <li key={el.label} className="w-full">
                  <button
                    type="button"
                    disabled={!el.active}
                    onClick={() => {
                      el.event();
                      setOpen(false);
                    }}
                    className="w-full px-6 py-2 text-left text-danger whitespace-nowrap hover:bg-secondary-gray-light-hover focus:bg-secondary-gray-light-hover focus:outline-none disabled:bg-transparent"
                  >
                    {el.label}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

UserSettingsDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      event: PropTypes.func.isRequired,
      active: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
};

export default UserSettingsDropdown;
