import React from 'react';

const STATUS = {
  PENDING: {
    label: 'PENDING',
    color: 'text-secondary',
    icon: (
      <svg viewBox="0 0 20 21" className="w-5 h-5">
        <path
          d="M15.8063 3.50327C15.5325 3.31372 15.1534 3.3769 14.9639 3.65071C14.7743 3.92451 14.8375 4.30362 15.1113 4.49317C17.2596 6.00961 18.5443 8.49488 18.5443 11.1276C18.5443 15.6348 14.8796 19.2995 10.3724 19.2995C5.86523 19.2995 2.20051 15.6348 2.20051 11.1276C2.20051 6.66252 5.80205 3.01886 10.2671 2.97673V3.90345C10.2671 4.17725 10.583 4.34574 10.8147 4.19831L13.2789 2.68187C13.5106 2.53444 13.5106 2.21852 13.2789 2.07108L10.8147 0.554648C10.583 0.407217 10.2671 0.57571 10.2671 0.849511V1.77622C5.14914 1.83941 1 6.00961 1 11.1276C1 16.2877 5.19126 20.5 10.3724 20.5C15.5536 20.5 19.7448 16.3087 19.7448 11.1276C19.7448 8.11577 18.2705 5.27245 15.8063 3.50327Z"
          fill="#5D6A74"
        />
        <path
          d="M4.51758 11.1276C4.51758 14.35 7.15028 16.9827 10.3727 16.9827C13.5951 16.9827 16.2278 14.3711 16.2278 11.1276C16.2278 7.90516 13.5951 5.27246 10.3727 5.27246C7.15028 5.27246 4.51758 7.90516 4.51758 11.1276ZM10.9624 8.32639V10.5168H12.7527C13.0897 10.5168 13.3424 10.7906 13.3424 11.1065C13.3424 11.4435 13.0686 11.6963 12.7527 11.6963H10.3727C10.0357 11.6963 9.78298 11.4225 9.78298 11.1065V8.32639C9.78298 7.98941 10.0568 7.73667 10.3727 7.73667C10.6886 7.73667 10.9624 8.01047 10.9624 8.32639Z"
          fill="#5D6A74"
        />
      </svg>
    ),
  },
  VERIFIED: {
    label: 'VERIFIED',
    color: 'text-primary-light',
    icon: (
      <svg viewBox="0 0 20 21" className="w-5 h-5">
        <g clipPath="url(#clip0)">
          <path
            d="M20 10.5C20 16.0229 15.5229 20.5 10 20.5C4.47714 20.5 0 16.0229 0 10.5C0 4.97714 4.47714 0.5 10 0.5C15.5229 0.5 20 4.97714 20 10.5ZM8.84331 15.7949L16.2627 8.37556C16.5146 8.12363 16.5146 7.71512 16.2627 7.46319L15.3503 6.55081C15.0983 6.29883 14.6898 6.29883 14.4379 6.55081L8.3871 12.6015L5.56214 9.77657C5.3102 9.52464 4.90169 9.52464 4.64972 9.77657L3.73734 10.689C3.4854 10.9409 3.4854 11.3494 3.73734 11.6013L7.93089 15.7949C8.18286 16.0469 8.59133 16.0469 8.84331 15.7949Z"
            fill="#00BFA5"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  UNVERIFIED: {
    label: 'UNVERIFIED',
    color: 'text-warning',
    icon: (
      <svg viewBox="0 0 23 21" className="w-5 h-5">
        <path
          d="M22.4721 18.3594L12.5654 1.2094C12.0178 0.263535 10.6488 0.263535 10.1012 1.2094L0.194575 18.3594C-0.353029 19.3052 0.319033 20.5 1.41424 20.5H21.2276C22.3477 20.5 23.0197 19.3052 22.4721 18.3594ZM11.0222 6.46142C11.6196 6.31207 12.217 6.61077 12.4908 7.15837C12.5903 7.3575 12.6152 7.58152 12.5903 7.78065C12.5406 8.40293 12.5157 9.0252 12.4908 9.67237C12.441 10.6431 12.3663 11.6388 12.3165 12.6095C12.2916 12.9082 12.2916 13.2069 12.2668 13.5305C12.2419 14.0532 11.8436 14.4515 11.3209 14.4515C10.8231 14.4515 10.3999 14.0532 10.375 13.5554C10.3004 12.037 10.2008 10.5187 10.1261 9.00031C10.1012 8.60205 10.0763 8.17891 10.0515 7.78065C10.0515 7.18326 10.4497 6.61077 11.0222 6.46142ZM11.3458 17.8615C10.6488 17.8615 10.1012 17.289 10.1012 16.5921C10.1012 15.8951 10.6737 15.3226 11.3707 15.3226C12.0676 15.3226 12.6152 15.8951 12.6152 16.617C12.5903 17.289 12.0178 17.8615 11.3458 17.8615Z"
          fill="#F7981D"
        />
      </svg>
    ),
  },
  REVOKED: {
    label: 'REVOKED',
    color: 'text-danger',
    icon: (
      <svg viewBox="0 0 15 15" className="w-5 h-5">
        <path
          d="M12.5214 0.384615C13.0912 -0.128205 14.0028 -0.128205 14.5726 0.384615C15.1425 0.954416 15.1425 1.8661 14.5726 2.4359L9.5584 7.50712L14.5726 12.5214C15.1425 13.0912 15.1425 14.0028 14.5726 14.5726C14.0028 15.1425 13.0912 15.1425 12.5214 14.5726L7.50712 9.5584L2.4359 14.5726C1.8661 15.1425 0.954416 15.1425 0.384615 14.5726C-0.128205 14.0028 -0.128205 13.0912 0.384615 12.5214L5.45584 7.50712L0.384615 2.4359C-0.128205 1.8661 -0.128205 0.954416 0.384615 0.384615C0.954416 -0.128205 1.8661 -0.128205 2.4359 0.384615L7.50712 5.45584L12.5214 0.384615Z"
          fill="#E2574C"
        />
      </svg>
    ),
  },
  DELETED: {
    label: 'DELETED',
    color: 'text-danger',
    icon: (
      <svg viewBox="0 0 15 15" className="w-4 h-4">
        <path
          d="M12.5214 0.384615C13.0912 -0.128205 14.0028 -0.128205 14.5726 0.384615C15.1425 0.954416 15.1425 1.8661 14.5726 2.4359L9.5584 7.50712L14.5726 12.5214C15.1425 13.0912 15.1425 14.0028 14.5726 14.5726C14.0028 15.1425 13.0912 15.1425 12.5214 14.5726L7.50712 9.5584L2.4359 14.5726C1.8661 15.1425 0.954416 15.1425 0.384615 14.5726C-0.128205 14.0028 -0.128205 13.0912 0.384615 12.5214L5.45584 7.50712L0.384615 2.4359C-0.128205 1.8661 -0.128205 0.954416 0.384615 0.384615C0.954416 -0.128205 1.8661 -0.128205 2.4359 0.384615L7.50712 5.45584L12.5214 0.384615Z"
          fill="#E2574C"
        />
      </svg>
    ),
  },
};

export default STATUS;
