/* eslint-disable */
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

const { GATSBY_DEPLOYMENT } = process.env;
const resources = {
  'fr-FR': {
    common: require('./locales/fr-FR/common.json'),
    countries: require('./locales/fr-FR/countries.json'),
    states: require('./locales/fr-FR/states.json'),
    'sign-in': require('./locales/fr-FR/sign-in.json'),
    'sign-up': require('./locales/fr-FR/sign-up.json'),
    ftg: require('./locales/fr-FR/ftg.json'),
    hospitality: require('./locales/fr-FR/hospitality.json'),
    cars: require('./locales/fr-FR/cars.json'),
    venue: require('./locales/fr-FR/venue.json'),
    schools: require('./locales/fr-FR/schools.json'),
    cruises: require('./locales/fr-FR/cruises.json'),
    dekra: require('./locales/fr-FR/dekra.json'),
    vulcain: require('./locales/en-EN/vulcain.json'),
    vaccine: require('./locales/fr-FR/vaccine.json'),
    dashboard: require('./locales/fr-FR/dashboard.json'),
    facility: require('./locales/fr-FR/facility.json'),
    users: require('./locales/fr-FR/users.json'),
    greenshield: require('./locales/fr-FR/greenshield.json'),
  },
  'en-EN': {
    common: require('./locales/en-EN/common.json'),
    countries: require('./locales/en-EN/countries.json'),
    states: require('./locales/en-EN/states.json'),
    'sign-in': require('./locales/en-EN/sign-in.json'),
    'sign-up': require('./locales/en-EN/sign-up.json'),
    ftg: require('./locales/en-EN/ftg.json'),
    hospitality: require('./locales/en-EN/hospitality.json'),
    cars: require('./locales/en-EN/cars.json'),
    venue: require('./locales/en-EN/venue.json'),
    schools: require('./locales/en-EN/schools.json'),
    cruises: require('./locales/en-EN/cruises.json'),
    dekra: require('./locales/en-EN/dekra.json'),
    vulcain: require('./locales/en-EN/vulcain.json'),
    vaccine: require('./locales/en-EN/vaccine.json'),
    dashboard: require('./locales/en-EN/dashboard.json'),
    facility: require('./locales/en-EN/facility.json'),
    users: require('./locales/en-EN/users.json'),
    greenshield: require('./locales/en-EN/greenshield.json'),
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-EN',
    resources:
      GATSBY_DEPLOYMENT === 'PREPROD' || GATSBY_DEPLOYMENT === 'PROD'
        ? { 'en-EN': resources['en-EN'] }
        : { ...resources },
    defaultNS: 'common',
    returnObjects: true,
    interpolation: { escapeValue: false },
    react: { useSuspense: true },
  });

export default i18next;
