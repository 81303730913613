import { DateTime } from 'luxon';

const dateToDateFormat = (iso, lg) => {
  try {
    if (lg.startsWith('fr')) return DateTime.fromISO(iso).toFormat("dd'/'MM'/'yyyy");
    return DateTime.fromISO(iso).toFormat("MM'/'dd'/'yyyy");
  } catch (err) {
    return '';
  }
};

export default dateToDateFormat;
