import { DateTime } from 'luxon';

const DATE_FORMAT = 'yyyy-MM-dd';

const everyDays = (start, end) => {
  const startDate = DateTime.fromJSDate(start);
  const endDate = DateTime.fromJSDate(end);

  const diff = endDate.diff(startDate, 'days').toObject();
  if (diff.days < 1) return [startDate.toFormat(DATE_FORMAT)];

  const dates = [startDate.toFormat(DATE_FORMAT)];

  let currentDate = startDate;
  while (currentDate.toMillis() < endDate.toMillis()) {
    const currentPlus = currentDate.plus({ days: 1 });
    dates.push(currentPlus.toFormat(DATE_FORMAT));
    currentDate = currentPlus;
  }

  return dates;
};

const everyWeeks = (start, end) => {
  const startDate = DateTime.fromJSDate(start);
  const endDate = DateTime.fromJSDate(end);

  const diff = endDate.diff(startDate, 'days').toObject();
  if (diff.days < 7) return [startDate.toFormat(DATE_FORMAT)];

  const dates = [startDate.toFormat(DATE_FORMAT)];

  let currentDate = startDate;
  while (currentDate.plus({ days: 6 }).toMillis() < endDate.toMillis()) {
    const currentPlus = currentDate.plus({ days: 7 });
    dates.push(currentPlus.toFormat(DATE_FORMAT));
    currentDate = currentPlus;
  }

  return dates;
};

export { everyDays, everyWeeks };
