// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-views-static-vulcain-pages-cgus-jsx": () => import("./../../../src/views/static/vulcain/pages/CGUS.jsx" /* webpackChunkName: "component---src-views-static-vulcain-pages-cgus-jsx" */),
  "component---src-views-static-vulcain-pages-legals-jsx": () => import("./../../../src/views/static/vulcain/pages/Legals.jsx" /* webpackChunkName: "component---src-views-static-vulcain-pages-legals-jsx" */),
  "component---src-views-static-vulcain-pages-rgpd-jsx": () => import("./../../../src/views/static/vulcain/pages/RGPD.jsx" /* webpackChunkName: "component---src-views-static-vulcain-pages-rgpd-jsx" */),
  "component---src-views-static-vulcain-pages-user-guide-jsx": () => import("./../../../src/views/static/vulcain/pages/UserGuide.jsx" /* webpackChunkName: "component---src-views-static-vulcain-pages-user-guide-jsx" */)
}

