import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage, useField } from 'formik';

const Text = ({ name, label, namespaces, translate, autoComplete, disabled }) => {
  const { t } = useTranslation(translate ? namespaces : 'common');
  const [, meta] = useField(name);

  return (
    <div
      className={`w-full flex flex-col text-sm ${disabled ? 'cursor-not-allowed' : 'cursor-auto'}`}
    >
      <label id={name} htmlFor={name}>
        {translate ? t(label) : label}
      </label>
      <Field
        type="text"
        name={name}
        aria-labelledby={name}
        autoComplete={autoComplete}
        disabled={disabled}
        className={`px-3 py-2 mt-1 rounded transition border focus:outline-none disabled:pointer-events-none ${
          meta.error && meta.touched ? 'border-red-500' : 'border-gray-300 focus:border-black'
        } ${disabled ? 'opacity-40 cursor-not-allowed bg-gray-300' : 'opacity-100'}`}
      />
      <ErrorMessage name={name} component="div">
        {(msg) => <div className="py-1 text-sm text-red-500">{translate ? t(msg) : msg}</div>}
      </ErrorMessage>
    </div>
  );
};

Text.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  namespaces: PropTypes.arrayOf(PropTypes.string.isRequired),
  translate: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
};

Text.defaultProps = {
  autoComplete: 'off',
  namespaces: [],
  translate: true,
  disabled: false,
};

export default Text;
