const topicsDups = (topics, values) => {
  let error = false;
  const { assignments } = values;

  const topicIds = topics.map((t) => t.topicMasterId);
  const selectedIds = [];

  assignments.forEach((assignment) => {
    assignment.topics.forEach((topic) => {
      if (topic.selected && !selectedIds.includes(topic.topicMasterId))
        selectedIds.push(topic.topicMasterId);
    });
  });

  topicIds.forEach((id) => {
    if (!selectedIds.includes(id)) error = true;
  });

  return error;
};

export default topicsDups;
